@font-face {
    font-family: lato-regular;
    src: url(assets/fonts/Lato-Regular.ttf);
}

@font-face {
    font-family: lato-bold;
    src: url(assets/fonts/Lato-Bold.ttf);
}

body{
    padding: 15px;
    margin: 0;
    font-family: 'lato-regular';
    font-weight: normal;
}